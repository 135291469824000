<template>
  <k-field-group language-prefix="timeRegistration.filterFields">
    <k-sidebar
      v-bind="$attrs"
      :save-button-text="$t('global.applyFilter')"
      :title="$t('global.filterTitle', { module: $tc('timeRegistration.title', 2) })"
      fixed
      right
      temporary
      width="400"
      :cancel-button-text="$t('actions.cleanFilters')"
      :with-cancel-button="true"
      v-on="$listeners"
      @click:confirm="handleFilterClick"
      @click:cancel="handleCleanFilter"
    >
      <KCheckboxGroup
        v-model="form.timeRegistrationStatuses"
        :items="statuses"
        field="timeRegistrationStatus"
        dense
        hide-details
        item-text="text"
        item-value="value"
        multiple
      />
      <VSwitch
        v-model="form.isDeleted"
        class="pt-4"
        :label="$t('timeRegistration.filterFields.isDeleted')"
        inset
        dense
        hide-details
      />
      <EmployerAutocomplete
        v-model="form.employerIds"
        field="employer"
        grid="col-sm-12 mt-4 pt-1"
        :multiple="true"
        :return-object="false"
        hide-details
        item-text="name"
        item-value="id"
      />
      <CandidateAutocomplete
        v-model="form.candidateIds"
        field="candidate"
        grid="col-sm-12 mt-4 pt-1"
        :return-object="false"
        :multiple="true"
        hide-details
        item-text="name"
        item-value="id"
      />

      <PeriodFilter
        ref="periodFilter"
        :filters="filters"
        @filter="injectPeriodFilter"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import { timeRegistrationStatus } from '@/application/enums/timeRegistrationStatus.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import CandidateAutocomplete from '@/modules/candidate/components/CandidateAutocomplete.vue';
import PeriodFilter from '@/modules/timeRegistration/components/PeriodFilter.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';

export default {
  name: 'TimeRegistrationFilter',
  components: {
    KSidebar,
    KFieldGroup,
    KCheckboxGroup,
    EmployerAutocomplete,
    CandidateAutocomplete,
    PeriodFilter,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    statuses() {
      return getTranslatedEnumOptions(timeRegistrationStatus, 'timeRegistration.statusId');
    },
  },
  watch: {
    filters: {
      handler(value) {
        this.form = {
          ...this.form,
          ...value,
          isDeleted: value.isDeleted ?? true,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    injectPeriodFilter(value) {
      delete this.form.yearMonth;
      delete this.form.yearWeek;

      if (value) {
        this.form[`year${value.mode.charAt(0).toUpperCase() + value.mode.slice(1)}`] = value.value;
      }

    },
    handleFilterClick() {
      this.$emit('update:filters', {
        ...this.form,
        isDeleted: this.form.isDeleted ? undefined : false,
      });
      this.$emit('input', false);
    },
    handleCleanFilter() {
      this.form = {
        timeRegistrationStatuses: [],
      };
      this.$refs.periodFilter.clearPeriodFilter();
      this.$emit('update:filters', this.form);
    },
  },
};
</script>
