
import Vue, { PropType } from 'vue';
// @ts-ignore
import dayjs from '@/plugins/dayjs';

interface TimeRegistrationComponent {
  description: string,
  id: number,
  from: string,
  to: string,
  isBreak: boolean,
  hours: number,
  isBilled: boolean,
  isFactor: boolean,
  percentage: number,
  rate: number,
  total: number
}

export default Vue.extend({
  name: 'TimeRegistrationComponents',
  props: {
    timeRegistrationComponents: {
      type: Array,
      default: (): TimeRegistrationComponent[] => [],
    },
    label: {
      type: String,
    },
    hideDots: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatTime(date: string): string {
      return dayjs(date).format('HH:mm');
    },
    dstCorrectionText(dstCorrection: 1 | -1 | null): string | null {
      switch (dstCorrection) {
      case 1:
        return `(${this.$t('timeRegistration.components.dstCorrection+1')})`;
      case -1:
        return `(${this.$t('timeRegistration.components.dstCorrection-1')})`;
      default:
        return null;
      }
    },
    getFinancialValue(timeRegistrationComponent: TimeRegistrationComponent): string {
      if (timeRegistrationComponent.isFactor) {
        return `(${this.$t('timeRegistration.components.factor')} ${timeRegistrationComponent.percentage})`;
      }

      if (timeRegistrationComponent.percentage > 0) {
        return this.$n(timeRegistrationComponent.percentage / 100, 'percent');
      }
      
      return `(${this.$t('timeRegistration.components.rate')} ${this.$n(timeRegistrationComponent.rate / 100, 'currency')} / ${this.$tc('timeRegistration.components.hour')})`;
    },
  },
});
