<template>
  <v-radio-group
    v-model="filterMode"
    class="mt-6"
  >
    <template #label>
      <div class="d-flex align-baseline mb-2">
        <span>{{ $t("timeRegistration.filterFields.period") }}</span>
        <k-btn
          class="clear-period-filter text-lowercase ml-1"
          :disabled="!filterMode"
          small
          text
          @click="clearPeriodFilter"
        >
          {{ $t("timeRegistration.filterFields.clear") }}
        </k-btn>
      </div>
    </template>
    <v-radio value="week">
      <template #label>
        <div class="period-picker-wrapper d-flex align-center">
          <span>{{ $t("timeRegistration.filterFields.week") }}</span>
          <div
            v-if="filterMode === 'week'"
            class="d-flex ml-auto"
          >
            <KAutocomplete
              v-model="weekMonth"
              class="period-picker mr-4"
              :items="weeks"
              field="week"
            />
            <KAutocomplete
              v-model="year"
              class="period-picker"
              :items="years"
              field="year"
            />
          </div>
        </div>
      </template>
    </v-radio>
    <v-radio value="month">
      <template #label>
        <div class="period-picker-wrapper d-flex align-center">
          <span>{{ $t("timeRegistration.filterFields.month") }}</span>
          <div
            v-if="filterMode === 'month'"
            class="d-flex ml-auto"
          >
            <KAutocomplete
              v-model="weekMonth"
              class="period-picker mr-4"
              :items="months"
              field="month"
            />
            <KAutocomplete
              v-model="year"
              class="period-picker"
              :items="years"
              field="year"
            />
          </div>
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'PeriodFilter',
  components: {
    KAutocomplete,
    KBtn,
  },
  props: {
    form: {
      type: Object,
    },
    filters: {
      type: Object,
    },
  },
  emits: ['filter'],
  data() {
    return {
      filterMode: null,
      year: null,
      weekMonth: null,
    };
  },
  computed: {
    weeks() {
      return Array.from({ length: 53 }, (_, i) => i + 1).map((day) => ({
        text: day,
        value: day,
      }));
    },
    months() {
      return Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => ({
        text: this.$t('timeRegistration.filterFields.monthNamesShort')[index],
        value: month,
      }));
    },
    years() {
      const historyLength = 100;
      const currentYear = new Date().getFullYear();

      const years = [];

      for (let year = currentYear; year > currentYear - historyLength; year--) {
        years.push({
          text: year.toString(),
          value: year,
        });
      }
      return years;
    },
    constructPeriodFilter() {
      if (!this.filterMode || !this.year || !this.weekMonth) {
        return null;
      }
      return { mode: this.filterMode, value: this.year + this.weekMonth.toString().padStart(2, '0') };
    },
  },
  watch: {
    filters: {
      handler(value) {
        if (value.yearWeek || value.yearMonth) {
          this.deconstructPeriodFilter(value);
        }
      },
      deep: true,
      immediate: true,
    },
    filterMode() {
      this.weekMonth = null;
    },
    weekMonth() {
      this.$emit('filter', this.constructPeriodFilter);
    },
    year() {
      this.$emit('filter', this.constructPeriodFilter);
    },
  },
  methods: {
    deconstructPeriodFilter({ yearWeek, yearMonth }) {
      const activeFilter = yearWeek || yearMonth;

      this.weekMonth = Number(activeFilter.toString().substring(4));
      this.year = Number(activeFilter.toString().substring(0, 4));
      this.filterMode = yearWeek ? 'week' : yearMonth ? 'month' : null;
    },
    clearPeriodFilter() {
      this.filterMode = null;
      this.year = null;
      this.weekMonth = null;
      this.$emit('filter', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.period-picker-wrapper {
  width: 100%;
  height: 32px;

  .period-picker {
    width: 120px;
  }
}
</style>
