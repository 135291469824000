import Model from '@/application/models/model.js';
import dayjs from '@/plugins/dayjs.ts';
import Break from '@/application/models/Break.js';

export default class CandidateTimeRegistration extends Model {
  id = null;
  date = null;
  from = null;
  to = null;
  breaks = [new Break()];
  includeCommutingKilometers = false;
  includeBusinessKilometers = false;
  commutingKilometerAllowance = {
    kilometers: null,
    total: null,
  };
  businessKilometerAllowance = {
    kilometers: null,
    total: null,
  };
  saveConcept = false;

  mapForRequest() {
    /**
     * The date field is front-end only the api has 2 datetime stamps.
     */
    const fromTime = dayjs(`${this.date} ${this.from}`);
    let toTime = dayjs(`${this.date} ${this.to}`);
    if (fromTime.isAfter(toTime)) {
      toTime = toTime.add(1, 'day');
    }

    return {
      id: this.id || null,
      from: fromTime.format('YYYY-MM-DD HH:mm:ss'),
      to: toTime.format('YYYY-MM-DD HH:mm:ss'),
      commutingKilometerAllowance: this.commutingKilometerAllowance.kilometers === 0 ? undefined : this.mapKilometersAllowanceRequest(this.commutingKilometerAllowance),
      businessKilometerAllowance: this.businessKilometerAllowance.kilometers === 0 ? undefined : this.mapKilometersAllowanceRequest(this.businessKilometerAllowance),
      breaks: this.breaks.length > 0 ? this.breaks.map(singleBreak => {
        singleBreak.date = this.date;
        return singleBreak.mapForRequest(fromTime, toTime);
      }) : null,
    };
  }

  mapResponse(data) {
    const date = dayjs(data.from).format('YYYY-MM-DD');
    const from = dayjs(data.from).format('HH:mm');
    const to = dayjs(data.to).format('HH:mm');
    const breaks = data.breaks.map(singleBreak => new Break().mapResponse(singleBreak));
    return super.mapResponse({
      ...data,
      from,
      to,
      date,
      breaks,
    });
  }

  mapKilometersAllowanceRequest(input) {
    return {
      meters: input.kilometers ? Math.round(input.kilometers * 1000) : 0,
      total: input.total ? Math.round(input.total * 100) : 0,
    };
  }
}
