<template>
  <v-dialog
    v-if="timeRegistration"
    v-bind="$attrs"
    max-width="600"
  >
    <v-sheet class="pa-6">
      <v-row>
        <v-col>
          <h4 class="primary--text">
            {{ $t('actions.deleteConfirmation.title') }}
          </h4>
        </v-col>
        <v-btn
          color="grey lighten-2"
          fab
          x-small
          dark
          :elevation="0"
          @click="closeDialog"
        >
          <v-icon color="black">
            $close
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col
          v-if="timeRegistration.isBilled"
          class="text-body-1"
        >
          {{ $t('timeRegistration.messages.deleteBilled') }}
        </v-col>
        <v-col
          v-else
          class="text-body-1"
        >
          {{ $t('actions.deleteConfirmation.bodyAlt', { resource: $tc('timeRegistration.title', 1).toLowerCase() }) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form ref="form">
            <KTextarea
              v-model="reason"
              :field="$t('task.taskCompleteDialog.comment')"
              autofocus
              outlined
              required
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <VSpacer />
        <k-btn
          text
          class="mr-2"
          @click="closeDialog"
        >
          {{ $t('actions.cancel') }}
        </k-btn>
        <k-btn
          :disabled="!reason"
          @click="handleConfirm"
        >
          {{ $t('actions.continue') }}
        </k-btn>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KBtn from '@/components/KButton.vue';
import { deleteTimeRegistration } from '@/modules/timeRegistration/api';

export default {
  name: 'TimeRegistrationDeleteDialog',
  components: {
    KBtn,
    KTextarea,
  },
  props: {
    timeRegistration: {
      type: Object,
    },
  },
  emits: ['reload'],
  data: () => ({
    confirmCallback: null,
    reason: null,
  }),
  methods: {
    async handleConfirm() {
      try {
        await deleteTimeRegistration(this.timeRegistration.id, {
          reason: this.reason,
        });
        this.closeDialog();
        this.$emit('reload');
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('actions.deleteResource', { resource: this.$tc('timeRegistration.title', 1) }),
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('input', false);
    },
  },
};
</script>
