<template>
  <div>
    <v-row v-if="hasTimeRegistrationError">
      <v-col>
        <v-alert
          outlined
          type="error"
        >
          {{ $t('timeRegistration.errors.timeRegistration') }}
        </v-alert>
      </v-col>
    </v-row>
    <actionable-alert
      v-if="overlappingDate.from"
      :message="`${$t('timeRegistration.messages.overlappingTimeRegistration')}: ${dayjs(overlappingDate.from).format('LLL')} - ${dayjs(overlappingDate.to).format('HH:mm')}`"
    />
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="timeRegistrationsToAdd.timeRegistrations"
          :item-class="setRowConceptClass"
          disable-pagination
          hide-default-footer
        >
          <template #item.actions="{ item }">
            <v-btn
              v-if="item.id === null"
              color="red"
              icon
              @click.stop="handleDeleteClick(item)"
            >
              <v-icon small>
                $delete
              </v-icon>
            </v-btn>
            <v-btn
              v-if="item.id === null || item.saveConcept"
              color="text--secondary"
              icon
              @click.stop="openUpdate(item)"
            >
              <v-icon small>
                $edit
              </v-icon>
            </v-btn>
          </template>
          <template #item.saveConcept="{ item }">
            <span v-if="item.id > 0">
              <v-simple-checkbox
                v-model="item.saveConcept"
                :ripple="false"
              />
            </span>
          </template>
          <template #item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
          <template #item.breaks="{ item }">
            <table
              v-if="item.breaks.length"
              class="breaks-table d-inline"
            >
              <tbody>
                <tr
                  v-for="(singleBreak, idx) in item.breaks"
                  :key="idx"
                >
                  <td>{{ singleBreak.from }}</td>
                  <td style="padding: 0 10px;">
                    -
                  </td>
                  <td>{{ singleBreak.to }}</td>
                </tr>
              </tbody>
            </table>
            <span
              v-else
              class="error--text"
            >
              {{ shiftDuration(item) >= 5.5 ? $t('timeRegistration.messages.noBreak') : '' }}
            </span>
          </template>
          <template #item.commutingKilometerAllowance.kilometers="{ item }">
            <span>{{ item.commutingKilometerAllowance.kilometers ? $n((item.commutingKilometerAllowance.kilometers), 'twoDecimals') : 0 }}</span>
          </template>
          <template #item.businessKilometerAllowance.kilometers="{ item }">
            <span>{{ item.businessKilometerAllowance.kilometers ? $n((item.businessKilometerAllowance.kilometers), 'twoDecimals') : 0 }}</span>
          </template>
        </v-data-table>
        <NewTimeRegistrationUpdateForm
          :update-time-registration="updateTimeRegistration"
          :update-dialog="updateDialog"
          :update-key="updateKey"
          :time-registrations-to-add="timeRegistrationsToAdd"
          :update-time-registration-idx="updateTimeRegistrationIdx"
          @save-update="handleSaveUpdateClick"
          @cancel-update="handleCancelUpdateClick"
          @add-break="addBreak"
          @remove-break="removeBreak"
        />
      </v-col>
    </v-row>
    <v-form
      ref="newTimeRegistrationForm"
      v-model="newTimeRegistrationValid"
    >
      <v-row class="align-start">
        <v-col>
          <KDateField
            ref="newTimeRegistrationDatePicker"
            v-model="newTimeRegistration.date"
            field="date"
            required
            :min-date="timeRegistrationsToAdd.placement?.startDate"
            :max-date="dayjs(timeRegistrationsToAdd.placement?.endDate).isBefore(dayjs(), 'day') ? timeRegistrationsToAdd.placement?.endDate : dayjs()"
            @keypress.native.enter="handleKeyPressEnter"
          />
        </v-col>
        <v-col>
          <KTimeField
            v-model="newTimeRegistration.from"
            field="from"
            required
            @keypress.native.enter="handleKeyPressEnter"
          />
        </v-col>
        <v-col>
          <KTimeField
            v-model="newTimeRegistration.to"
            field="to"
            required
            :hint="toHint"
            @keypress.native.enter="handleKeyPressEnter"
          />
        </v-col>
        <v-col cols="4">
          <TimeRegistrationBreak
            v-for="(newBreak, idx) in newTimeRegistration.breaks"
            ref="timeRegistrationBreak"
            :key="`break_${idx}`"
            :time-registration-break="newBreak"
            :show-add-break-button="idx === newTimeRegistration.breaks.length - 1"
            :show-delete-break-button="newTimeRegistration.breaks && newTimeRegistration.breaks.length > 1"
            :new-time-registration="newTimeRegistration"
            @add-time-registration="handleAddTimeRegistrationClick"
            @add-break="addBreak(newTimeRegistration.breaks)"
            @remove-break="removeBreak(newTimeRegistration.breaks, idx)"
          />
        </v-col>
        <v-col>
          <k-btn
            class="mt-3"
            :disabled="!newTimeRegistrationValid"
            @click="handleAddTimeRegistrationClick"
          >
            {{ $t('timeRegistration.actions.add') }}
          </k-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="col-sm-1">
        <span>{{ $t('timeRegistration.fields.travelCosts') }}</span>
      </v-col>
      <v-col class="col-sm-2 pt-1">
        <KCheckbox
          v-model="newTimeRegistration.includeCommutingKilometers"
          :disabled="!commutingTotal"
          dense
          field="commutingTraffic"
        />
      </v-col>
      <v-col class="col-sm-1 text-right">
        <span :class="newTimeRegistration.includeCommutingKilometers ? '' : 'transparent-text'">{{
          $n((commutingKilometers), 'twoDecimals')
        }} km</span>
      </v-col>
      <v-col class="col-sm-1">
        <span :class="newTimeRegistration.includeCommutingKilometers ? '' : 'transparent-text'">{{
          $n(commutingTotal, 'currency')
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="col-sm-2 pt-1"
        offset-sm="1"
      >
        <KCheckbox
          v-model="newTimeRegistration.includeBusinessKilometers"
          dense
          field="businessTraffic"
        />
      </v-col>
      <v-col class="col-sm-1 pt-1">
        <KFigureField
          ref="newTimeRegistrationBusinessKilometers"
          v-model="newTimeRegistration.businessKilometerAllowance.kilometers"
          class="text-align-right"
          label=""
          autocomplete="off"
          dense
          :disabled="!newTimeRegistration.includeBusinessKilometers"
          min="0"
          step="0.01"
          suffix="km"
        />
      </v-col>
      <v-col class="col-sm-1">
        <span :class="newTimeRegistration.includeBusinessKilometers ? '' : 'transparent-text'">{{
          $n(businessTotal, 'currency')
        }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KDateField from '@/components/crud/fields/KDateField.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import CandidateTimeRegistration from '@/application/models/CandidateTimeRegistration.js';
import dayjs from '@/plugins/dayjs';
import { calculateBusinessTotal, getTravelCosts, checkOverlappingTimeRegistrations } from '@/modules/timeRegistration/api';
import cloneDeep from 'lodash.clonedeep';
import KBtn from '@/components/KButton.vue';
import Break from '@/application/models/Break.js';
import TimeRegistrationBreak
  from '@/modules/timeRegistration/components/TimeRegistrationBreak.vue';
import NewTimeRegistrationUpdateForm
  from '@/modules/timeRegistration/components/newTimeRegistrations/NewTimeRegistrationUpdateForm.vue';
import { mapState } from 'vuex';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';

export default {
  name: 'TimeRegistrationsTable',
  components: {
    NewTimeRegistrationUpdateForm,
    TimeRegistrationBreak,
    KBtn,
    KTimeField,
    KDateField,
    KCheckbox,
    KFigureField,
    ActionableAlert,
  },
  props: {
    timeRegistrationsToAdd: {
      type: Object,
      required: true,
    },
    conceptTimeRegistrations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      updateDialog: false,
      updateTimeRegistration: new CandidateTimeRegistration(),
      updateTimeRegistrationIdx: -1,
      newTimeRegistration: new CandidateTimeRegistration(),
      newTimeRegistrationValid: false,
      overlappingDate: { from: null, to: null },
      updateKey: 1,
      businessTotalTimeout: 0,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'saveConcept',
          width: '30px',
        },
        {
          text: this.$t('timeRegistration.fields.date'),
          value: 'date',
          align: 'end',
        },
        {
          text: this.$t('timeRegistration.fields.from'),
          value: 'from',
          align: 'end',
        },
        {
          text: this.$t('timeRegistration.fields.to'),
          value: 'to',
          align: 'end',
        },
        {
          text: this.$t('timeRegistration.crudHeaders.breaks'),
          value: 'breaks',
          align: 'end',
        },
        {
          text: this.$t('timeRegistration.crudHeaders.commutingTraffic'),
          value: 'commutingKilometerAllowance.kilometers',
          align: 'end',
        },
        {
          text: this.$t('timeRegistration.crudHeaders.businessTraffic'),
          value: 'businessKilometerAllowance.kilometers',
          align: 'end',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,

        },
      ],
      newTimeRegistrations: [],
      commutingKilometers: 0,
      commutingTotal: 0,
      businessKilometerAllowance: 0,
      businessTotal: 0,
    };
  },
  computed: {
    ...mapState('error', ['errors']),
    hasTimeRegistrationError() {
      if (this.errors.length > 0) {
        return this.errors[0].key === 'timeRegistrations';
      }
      return false;
    },
    toHint() {
      if (!this.newTimeRegistration.from || !this.newTimeRegistration.to) {
        return null;
      }

      const [fromHour, fromMinutes] = this.newTimeRegistration.from.split(':');
      const [toHour, toMinutes] = this.newTimeRegistration.to.split(':');

      const from = dayjs().hour(fromHour).minute(fromMinutes);
      const to = dayjs().hour(toHour).minute(toMinutes);

      if (to.isAfter(from)) {
        return null;
      }

      if (this.newTimeRegistration.date) {
        const nextDay = dayjs(this.newTimeRegistration.date).add(1, 'day').format('LL');
        return this.$tc('timeRegistration.messages.toTimeDay', 2, { date: nextDay });
      }

      return this.$tc('timeRegistration.messages.toTimeDay', 1);

    },
  },
  watch: {
    'newTimeRegistration.businessKilometerAllowance.kilometers': {
      async handler(newVal) {
        clearTimeout(this.businessTotalTimeout);
        this.businessTotalTimeout = setTimeout(async () => {
          const response = await this.calculateBusinessTotal(newVal);
          this.businessTotal = response.data.data.total / 100;
        }, 300);
      },
    },
    'travelCosts.commutingKilometerAllowance': {
      handler(newVal) {
        this.newTimeRegistration.commutingKilometerAllowance = newVal;
      },
    },
    'updateTimeRegistration.businessKilometerAllowance.kilometers': {
      async handler(newVal) {
        clearTimeout(this.businessTotalTimeout);
        this.businessTotalTimeout = setTimeout(async () => {
          const response = await this.calculateBusinessTotal(newVal);
          this.updateTimeRegistration.businessKilometerAllowance.total = response.data.data.total / 100;
        }, 300);
      },
    },
    'updateTimeRegistration.includeCommutingKilometers': {
      handler(newVal) {
        if (this.updateTimeRegistration === null || this.updateTimeRegistration === undefined) {
          return;
        }

        this.updateTimeRegistration.commutingKilometerAllowance.kilometers = newVal ? this.commutingKilometers : 0;
        this.updateTimeRegistration.commutingKilometerAllowance.total = newVal ? this.commutingTotal : 0;
      },
    },
    'updateTimeRegistration.includeBusinessKilometers': {
      handler(newVal) {
        if (this.updateTimeRegistration === null || this.updateTimeRegistration === undefined) {
          return;
        }

        this.updateTimeRegistration.businessKilometerAllowance.kilometers = newVal ? this.updateTimeRegistration.businessKilometerAllowance.kilometers : 0;
        this.updateTimeRegistration.businessKilometerAllowance.total = newVal ? this.businessTotal : 0;
      },
    },
    'newTimeRegistration.from'() {
      this.validateBreaks();
    },
    'newTimeRegistration.to'() {
      this.validateBreaks();
    },
  },
  created() {
    this.newTimeRegistration.includeCommutingKilometers = this.timeRegistrationsToAdd.placement.usesFixedCommutingTravelCosts;
    this.getTravelCosts();
  },
  methods: {
    dayjs,
    async addBreak(breaks) {
      breaks.push(new Break());
      await this.$nextTick();

      const breakRows = this.$refs.timeRegistrationBreak;
      breakRows[breakRows.length -1].$refs.from.$refs.field.focus();
    },
    shiftDuration({ date, from, to }) {
      const shiftFrom = dayjs(`${date} ${from}`);
      let shiftTo = dayjs(`${date} ${to}`);

      if (shiftTo.isBefore(shiftFrom)) {
        shiftTo = shiftTo.add(1, 'day');
      }
      return dayjs.duration(shiftTo.diff(shiftFrom)).asHours();
    },
    removeBreak(breaks, idx) {
      breaks.splice(idx, 1);
    },
    handleKeyPressEnter() {
      this.handleAddTimeRegistrationClick();
    },
    setKiloMeterAllowances() {

      if (this.newTimeRegistration.includeCommutingKilometers) {
        this.newTimeRegistration.commutingKilometerAllowance.kilometers = this.commutingKilometers;
        this.newTimeRegistration.commutingKilometerAllowance.total = this.commutingTotal;
      } else {
        this.newTimeRegistration.commutingKilometerAllowance.kilometers = 0;
        this.newTimeRegistration.commutingKilometerAllowance.total = 0;
      }

      if (this.newTimeRegistration.includeBusinessKilometers) {
        this.newTimeRegistration.businessKilometerAllowance.total = this.businessTotal;
      } else {
        this.newTimeRegistration.businessKilometerAllowance.kilometers = 0;
        this.newTimeRegistration.businessKilometerAllowance.total = 0;
      }
    },
    filterOutEmptyBreaks() {
      this.newTimeRegistration.breaks = this.newTimeRegistration.breaks.filter(singleBreak => singleBreak.from && singleBreak.to );
    },
    async insertNewTimeRegistrationRow() {
      const date = this.newTimeRegistration.date;
      const includeCommutingKilometersIsChecked = this.newTimeRegistration.includeCommutingKilometers;
      const businessKilometers = this.newTimeRegistration.businessKilometerAllowance.kilometers;
      this.newTimeRegistration = new CandidateTimeRegistration();
      this.$refs.newTimeRegistrationForm.reset();

      await this.$nextTick();

      this.$set(this.newTimeRegistration, 'date', date);
      this.newTimeRegistration.includeCommutingKilometers = includeCommutingKilometersIsChecked;
      this.newTimeRegistration.businessKilometerAllowance.kilometers = businessKilometers;
      
      this.$refs.newTimeRegistrationDatePicker.$refs.field.focus();
    },
    async handleAddTimeRegistrationClick() {
      if (!this.newTimeRegistrationValid) return;

      const res = await checkOverlappingTimeRegistrations({
        candidateId: this.timeRegistrationsToAdd.candidateId,
        timeRegistrations: [...this.timeRegistrationsToAdd.timeRegistrations, this.newTimeRegistration],
      });

      this.overlappingDate = res.data.data;

      if (this.overlappingDate.from) return;

      this.setKiloMeterAllowances();
      this.filterOutEmptyBreaks();

      this.timeRegistrationsToAdd.timeRegistrations.push(this.newTimeRegistration);
      
      this.insertNewTimeRegistrationRow();
    },
    async calculateBusinessTotal(kilometers) {
      const meters = Math.round((kilometers || 0) * 1000);
      return await calculateBusinessTotal(this.timeRegistrationsToAdd.placement.id, meters);
    },
    openUpdate(item) {
      this.updateKey++;
      this.updateTimeRegistration = null;
      this.updateTimeRegistrationIdx = this.timeRegistrationsToAdd.timeRegistrations.indexOf(item);
      this.updateTimeRegistration = cloneDeep(item);
      this.updateDialog = true;
    },
    handleCancelUpdateClick() {
      this.updateDialog = false;
      this.updateTimeRegistration = new CandidateTimeRegistration();
    },
    handleSaveUpdateClick() {
      if (this.updateTimeRegistrationIdx > -1) {
        // filter out empty breaks
        this.updateTimeRegistration.breaks = this.updateTimeRegistration.breaks.filter(singleBreak => singleBreak.from !== null);
        this.timeRegistrationsToAdd.timeRegistrations.splice(this.updateTimeRegistrationIdx, 1, this.updateTimeRegistration);
      }
      this.updateDialog = false;
    },
    handleDeleteClick(item) {
      const idx = this.timeRegistrationsToAdd.timeRegistrations.indexOf(item);
      if (idx > -1) {
        this.timeRegistrationsToAdd.timeRegistrations.splice(idx, 1);
      }
    },
    formatDate(date) {
      return dayjs(date).format('DD-MM-YYYY');
    },
    setRowConceptClass(item) {
      return item.id > 0 && !item.saveConcept ? 'text--disabled' : '';
    },
    async getTravelCosts() {
      const candidateId = this.timeRegistrationsToAdd.candidateId;
      const placementId = this.timeRegistrationsToAdd.placement?.id;
      const locationId = this.timeRegistrationsToAdd.location?.id;
      const departmentId = this.timeRegistrationsToAdd.departmentId;
      
      if (!candidateId || !placementId || !locationId ) return;

      const response = await getTravelCosts({ candidateId, placementId, locationId, departmentId });
      this.commutingKilometers = response.data.data.commutingKilometerAllowance.meters / 1000;
      this.commutingTotal = response.data.data.commutingKilometerAllowance.total / 100;
      this.businessKilometerAllowance = response.data.data.businessKilometerAllowance.allowance / 100;
    },
    validateBreaks() {
      if (this.newTimeRegistration.from && this.newTimeRegistration.to && this.newTimeRegistration.breaks[0].from && this.newTimeRegistration.breaks[0].to) {
        this.$refs.newTimeRegistrationForm.validate();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.transparent-text {
  opacity: 0.50;
}

:deep(.text-align-right) input {
  text-align: end
}

:deep(.v-data-table) table.breaks-table tbody tr:nth-child(odd) {
  background-color: transparent;
}

</style>
