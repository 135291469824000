<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      {{ $tc('timeRegistration.title', 2) }}
    </template>
    <template #filters="{ attrs, on }">
      <TimeRegistrationFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="handleOpenCreateDialog"
      >
        {{ $t('actions.createResource', { resource: $tc('timeRegistration.title', 1) }) }}
      </v-btn>
      <TimeRegistrationsForm
        ref="timeRegistrationsForm"
        v-model="createDialog"
        :request="createRequest"
        :values="timeRegistrationsToAdd"
        :title="$tc('timeRegistration.title', 1)"
        @change="$refs.table.reload()"
      />
      <TimeRegistrationUpdateForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('timeRegistration.title', 1)"
        :values="updateFormValues"
        is-update-form
        @change="reloadTable"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :params="filters"
        :search="searchQuery"
        language-prefix="timeRegistration.headers"
        show-expand
        resource="timeRegistration"
      >
        <template #item.from="{ item }">
          {{ formatDate(item.from) }}
        </template>
        <template #item.timeDifference="{ item: { timeDifference } }">
          <span
            v-if="timeDifference < 0"
            class="orange--text"
          >{{ localizeTime(timeDifference) }}</span>
          <span
            v-else-if="timeDifference > 0"
            class="red--text"
          >{{ localizeTime(timeDifference) }}</span>
          <span
            v-else
            class="green--text"
          >{{ localizeTime(timeDifference) }}</span>
        </template>

        <template #item.breakTime="{ item: { breakTime } }">
          <span v-if="breakTime">
            {{ localizeTime(breakTime) }}
          </span>
          <span
            v-else
            class="text--disabled"
          >{{ $t('timeRegistration.display.noBreak') }}</span>
        </template>

        <template #item.timeRegistrationStatusId="{ item: { timeRegistrationStatusId, deletedAt } }">
          <span
            v-if="deletedAt"
            class="text--disabled"
          >{{ $t(`timeRegistration.display.deleted`) }}</span>
          <span v-else>{{ getTranslatedEnumValue(timeRegistrationStatus, timeRegistrationStatusId, 'timeRegistration.statusId') }}</span>
        </template>
        <template #actions="{ item: { timeRegistrationStatusId }, item }">
          <v-tooltip
            top
            :disabled="canDelete(item)"
          >
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn
                  color="red"
                  icon
                  :disabled="!canDelete(item)"
                  @click="deleteTimeRegistration(item)"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </span>
            </template>
            <span v-if="item.deletedAt"> {{ $t('timeRegistration.messages.deleteDisabledDeleted') }} </span>
            <span v-else-if="item.isBilled"> {{ $t('timeRegistration.messages.deleteDisabledBilled') }} </span>
            <span v-else> {{ $t('timeRegistration.messages.deleteDisabledApproved') }} </span>
          </v-tooltip>
          <v-tooltip
            top
            :disabled="canEdit(item)"
          >
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn
                  icon
                  :disabled="!canEdit(item)"
                  @click="openUpdate(item)"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </span>
            </template>
            <span v-if="item.deletedAt"> {{ $t('timeRegistration.messages.editDisabledDeleted') }} </span>
            <span v-else-if="item.isBilled"> {{ $t('timeRegistration.messages.editDisabledBilled') }} </span>
            <span v-else> {{ $t('timeRegistration.messages.editDisabledApproved') }} </span>
          </v-tooltip>
          <v-btn
            v-if="timeRegistrationStatusId === timeRegistrationStatus.WAIT_FOR_INTERMEDIARY"
            color="primary"
            depressed
            tile
            @click="approveTimeRegistration(item)"
          >
            {{ $t('timeRegistration.actions.approve') }}
          </v-btn>
        </template>
        <template #expanded-item="{ item }">
          <TimeRegistrationExpandDetails
            :ref="`registrationDetails_${item.id}`"
            :time-registration-id="item.id"
          />
        </template>
      </k-crud-table>
      <TimeRegistrationDeleteDialog
        v-model="showDeleteDialog"
        :time-registration="timeRegistrationToBeDeleted"
        @reload="reloadTable"
      />
    </template>
  </KCrudLayout>
</template>

<script>
import { timeRegistrationStatus } from '@/application/enums/timeRegistrationStatus.js';
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import {
  approve,
  index,
  saveTimeRegistrations,
  show,
  update,
} from '@/modules/timeRegistration/api';
import TimeRegistrationFilter from '@/modules/timeRegistration/components/TimeRegistrationFilter.vue';
import dayjs from '@/plugins/dayjs';
import TimeRegistrationExpandDetails from '@/modules/timeRegistration/components/TimeRegistrationExpandDetails.vue';
import TimeRegistrationsForm
  from '@/modules/timeRegistration/components/newTimeRegistrations/TimeRegistrationsForm.vue';
import TimeRegistrationUpdateForm from '@/modules/timeRegistration/components/TimeRegistrationUpdateForm.vue';
import TimeRegistrationUpdate from '@/application/models/TimeRegistrationUpdate.js';
import { mapMutations } from 'vuex';
import { getTranslatedEnumValue } from '@/application/helpers/enum.js';
import TimeRegistrationDeleteDialog from '@/modules/timeRegistration/components/TimeRegistrationDeleteDialog.vue';

export default {
  name: 'TimeRegistrationIndex',
  components: {
    TimeRegistrationUpdateForm,
    TimeRegistrationsForm,
    TimeRegistrationExpandDetails,
    TimeRegistrationFilter,
    TimeRegistrationDeleteDialog,
    KCrudTable,
    KCrudLayout,
  },
  mixins: [querySyncMixin],
  data() {
    return {
      searchQuery: '',
      timeRegistrationStatus,
      filters: {},
      sortBy: 'from',
      sortDesc: true,
      createDialog: false,
      updateFormValues: new TimeRegistrationUpdate(),
      updateDialog: false,
      timeRegistrationsToAdd: {
        candidateId: null,
        placement: null,
        location: null,
        departmentId: null,
        timeRegistrations: [],
      },
      showDeleteDialog: false,
      timeRegistrationToBeDeleted: null,
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'candidateName',
          language: 'candidateName',
        },
        {
          value: 'employerName',
          language: 'employer',
        },
        {
          value: 'from',
          language: 'date',
        },
        {
          value: 'week',
          language: 'week',
        },
        // {
        //   value: 'timeDifference',
        //   language: 'difference',
        // },
        {
          value: 'breakTime',
          language: 'breakTime',
        },
        {
          value: 'timeRegistrationStatusId',
          language: 'status',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'timeRegistration.index' },
        text: this.$tc('timeRegistration.title', 2),
      },
    ]);
  },
  methods: {
    ...mapMutations('error', { clearErrors: 'clear' }),
    getTranslatedEnumValue,
    handleOpenCreateDialog() {
      this.$refs.timeRegistrationsForm.resetTimeRegistrations();
      this.createDialog = true;
    },
    indexRequest() {
      return index(...arguments);
    },
    formatDate(date) {
      return dayjs(date).format('LL');
    },
    async approveTimeRegistration({ id }) {
      await approve(id);
      this.$refs.table.reload();
    },
    localizeTime(timeDifference) {
      return `${Number.parseFloat(`${timeDifference}`).toLocaleString()} ${this.$tc('global.timeUnits.minute', 2)}`;
    },
    createRequest() {
      const oldTimeRegistrations = this.timeRegistrationsToAdd.timeRegistrations.filter(timeRegistration => {
        return dayjs(timeRegistration.date).isBefore(dayjs().subtract(3, 'month'));
      });

      if (!oldTimeRegistrations.length) {
        return this.handleSaveTimeRegistrations();
      }
      
      return new Promise((resolve, reject) => {
        eventBus.$emit('confirm', {
          title: this.$t('timeRegistration.messages.oldTimeRegistrationsTitle'),
          body: `<p>${this.$t('timeRegistration.messages.oldTimeRegistrationsBody')}</p>
          <ul>  
            ${oldTimeRegistrations.map(timeRegistration => { return `<li>${dayjs(timeRegistration.date).format('LL')} ${timeRegistration.from} - ${timeRegistration.to}</li>`; }).join('')}
          </ul>`,
          confirmCallback: async () => {
            resolve(this.handleSaveTimeRegistrations());
          },
          cancelCallback: async () => {
            reject({ gracefullyFail: true });
          },
          persistent: true,
        });
      });
    },
    async handleSaveTimeRegistrations() {
      // todo: handle missing candidateId properly instead of this brute force method
      if (this.timeRegistrationsToAdd.candidateId === null) throw new Error();
      const newTimeRegistrations = [];
      this.timeRegistrationsToAdd.timeRegistrations.forEach(timeRegistration => {
        if (timeRegistration.id === null || timeRegistration.saveConcept) {
          newTimeRegistrations.push(timeRegistration.mapForRequest());
        }
      });

      await saveTimeRegistrations({
        candidateId: this.timeRegistrationsToAdd.candidateId,
        placementId: this.timeRegistrationsToAdd.placement.id,
        locationId: this.timeRegistrationsToAdd.location.id,
        departmentId: this.timeRegistrationsToAdd.departmentId,
        timeRegistrations: newTimeRegistrations,
      });
      
      this.clearErrors();
    },
    canEdit(item) {
      return item.isEditable && !item.deletedAt;
    },
    canDelete(item) {
      return item.isDeletable && !item.deletedAt;
    },
    reloadTable() {
      const details = this.$refs[`registrationDetails_${this.updateFormValues?.id}`];
      this.$refs.table.reload();
      if (details) {
        details.fetchDetails(this.updateFormValues.id);
      }
    },
    updateRequest() {
      return update(this.updateFormValues)
        .then(() => this.clearErrors());
    },
    async openUpdate(item) {
      this.updateFormValues = new TimeRegistrationUpdate();
      const response = await show(item.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateFormValues.isBilled = item.isBilled;
      this.updateDialog = true;
    },
    async deleteTimeRegistration(item) {
      this.showDeleteDialog = true;
      this.timeRegistrationToBeDeleted = item;
    },
  },
};
</script>
