
import Vue, { PropType } from 'vue';
import CandidateTimeRegistration from '@/application/models/CandidateTimeRegistration.js';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import TimeRegistrationBreak
  from '@/modules/timeRegistration/components/TimeRegistrationBreak.vue';
import KBtn from '@/components/KButton.vue';
import dayjs from '@/plugins/dayjs';
import { checkOverlappingTimeRegistrations } from '@/modules/timeRegistration/api';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';

interface ComponentData {
  updateTimeRegistrationValid: boolean;
  overlappingDate: {
    from: string | null;
    to: string | null;
  },
}

interface ShiftTimes {
  date: string;
  from: string;
  to: string;
}

interface TimeRegistrationsToAdd {
  candidateId: number,
  departmentId?: number
  location: {
    id: number,
    name: string,
    costCenter: string,
  }
  placement: {
    employerId: number,
    id: number,
    name: string
  },
  timeRegistrations: {
    date: string,
    from: string,
    to: string,
  }[]
}

export default Vue.extend({
  name: 'NewTimeRegistrationUpdateForm',
  components: {
    KBtn,
    TimeRegistrationBreak,
    KFigureField,
    KCheckbox,
    KTimeField,
    KDateField,
    ActionableAlert,
  },
  props: {
    updateDialog: {
      type: Boolean,
      default: false,
    },
    updateTimeRegistration: {
      type: Object as () => PropType<CandidateTimeRegistration>,
    },
    updateKey: {
      type: Number,
    },
    updateTimeRegistrationIdx: {
      type: Number,
    },
    timeRegistrationsToAdd: {
      type: Object as () => TimeRegistrationsToAdd,
      required: true,
    },
  },
  data: (): ComponentData => ({
    updateTimeRegistrationValid: false,
    overlappingDate: { from: null, to: null },
  }),
  methods: {
    dayjs,
    shiftDuration({ date, from, to }: ShiftTimes) {
      const shiftFrom = dayjs(`${date} ${from}`);
      let shiftTo = dayjs(`${date} ${to}`);

      if (shiftTo.isBefore(shiftFrom)) {
        shiftTo = shiftTo.add(1, 'day');
      }
      return dayjs.duration(shiftTo.diff(shiftFrom)).asHours();
    },
    async saveUpdate() {
      const otherTimeRegistrations = this.timeRegistrationsToAdd.timeRegistrations.filter((_, index ) => index !== this.updateTimeRegistrationIdx);
      
      const res = await checkOverlappingTimeRegistrations({
        candidateId: this.timeRegistrationsToAdd?.candidateId,
        timeRegistrations: [...otherTimeRegistrations, this.updateTimeRegistration],
      });

      this.overlappingDate = res.data.data;

      if (this.overlappingDate.from) return;

      this.$emit('save-update');
    },
  },
});
